//
// Code
//

code:not([class*="language-"]) {
    font-weight: $code-font-weight;
    color: var(--#{$prefix}code-color);
    border: 1px solid var(--#{$prefix}code-border-color);
    background-color: var(--#{$prefix}code-bg);
    @include border-radius($code-border-radius);
    line-height: inherit;
    font-size: $code-font-size;
    padding: $code-padding;
    margin: $code-margin;        
    box-shadow: $code-shadow;

    @each $name, $value in $theme-colors {
        &.code-#{$name} {
            color: var(--#{$prefix}#{$name});
            background-color: var(--#{$prefix}#{$name}-light);
            border: 1px solid var(--#{$prefix}#{$name});
        }
    }
}
