// React vendors
@import 'socicon/css/socicon.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'prism-themes/themes/prism-shades-of-purple.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import 'animate.css/animate.css';
@import 'nouislider/dist/nouislider.css';
@import 'flatpickr/dist/flatpickr.css';

//
// Vendors customization
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// Initialize
@import "init";

// Plugins
@import "./core/vendors/plugins/plugins";
@import "vendors/react-select.scss";

#root {
  display: contents;
}
